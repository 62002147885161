import React, { useEffect, useState } from 'react'
import { getAllmerchantPayout } from 'src/api/merchantServices'
import errorHandler from 'src/utils/errorHandler'
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Input, InputAdornment, Tooltip } from '@mui/material'
import DataTable from 'react-data-table-component'
import CustomLoader from 'src/components/CustomLoader'
import { formatDate, getMerchantPayoutStatus, getSrno } from 'src/utils/helpers'
import { CurrentDateFormatWithTime } from 'src/utils/constants'
import couponTemplateServices from 'src/api/couponTemplateServices'
import FilterByUser from 'src/components/FilterByUser'
import MerchantStatus from './changeStatus'
import { MerchantPayoutServices } from 'src/api/merchantPayoutServices'
import MerchantPayoutView from './view'
import MerchantPayoutExcelBtn from 'src/components/DownloadMerchantExcelBtn'
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from 'react-router-dom'
import TableFilter from 'src/components/shared/TableFilter'
import { SearchBtn2 } from 'src/components/SearchBtn'


const STATUS_OPTIONS = [
    { label: "Calculating", value: "0" },
    { label: "Settled", value: "1" },
    { label: "Cancelled", value: "3" }
]


const MerchantPayout = () => {
    const navigate = useNavigate()
    const [searchItem, setSearchItem] = useState("");

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        sortBy: "",
        sortOrder: ""
    })

    const [status, setStatus] = useState([])
    const [data, setData] = useState([])

    // const [sort, setSort] = useState({
    //     sortBy: "",
    //     asc: ""   // acs, desc
    // })

    const fetchMerchantPayout = async () => {
        try {
            setLoading(true)
            payload.status = status

            payload.searchItem = searchItem


            let res = await MerchantPayoutServices.getAllmerchantPayout(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchMerchantPayout()
    }, [payload, status, searchItem])


    const fetchExcelData = async () => {
        try {
            const res = await MerchantPayoutServices.getMerchantPayoutExportData()
            console.log("res", res)
            setData(res?.data?.data)
        } catch (error) {
            errorHandler(error)
        }
    }



    useEffect(() => {
        fetchExcelData()

    }, [])

    const handleCallback = () => {
        fetchMerchantPayout()
        fetchExcelData()
    }


    const columns = [
        {
            name: "Sr. No.",
            grow: 0.6,
            cell: (row, index) => getSrno(payload.pageNumber, payload.pageSize, index),
        },
        {
            name: "Merchant Id",
            selector: (row) => row?.merchantId
        },
        {
            name: "Business Name",
            cell: (row) => row?.businessName,
            sortable: true,
            sortField: "businessName"
        },
        {
            name: "Paid Date",
            cell: (row) => formatDate(row?.paidDate, CurrentDateFormatWithTime),
        },
        {
            name: "Amount",
            cell: (row) => `$${row?.totalAmount}`,
            sortable: true,
            sortField: "totalAmount"
        },

        {
            name: "Created At",
            cell: (row) => formatDate(row?.createdAt),
        },
        {
            name: "Status",
            cell: (row) => getMerchantPayoutStatus(row?.status),
        },
        {
            name: "Action",
            grow: 0.4,
            cell: (row) => (
                <Tooltip title="View" placement="top">
                    <IconButton
                        color='primary'
                        onClick={() => navigate(`/merchant-payout/view/${row?.merchantId}/${row?.status}`)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            )
        },
        {
            name: "Change Payment Status",
            allowOverflow: true,
            width: "280px", 
            selector: (row) => (
                <>
                    <MerchantPayoutExcelBtn callback={handleCallback} showCancelledButton={true} merchantData={row} data={data} id={row?.merchantId} />
                </>)
        },
    ]


    const handleSort = (column, sortDirection) => {
        console.log(column?.sortField, "column?.sortField")
        setPayload(prevPayload => ({
            ...prevPayload,
            sortBy: column?.sortField,
            sortOrder: sortDirection
        }));
    }







    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Merchant Payout Management"
                >
                </CardHeader>
                <Grid container display={"flex"} justifyContent={"flex-end"} alignItems={"end"} gap={2}>
                    <MerchantPayoutExcelBtn callback={handleCallback} showCancelledButton={false} data={data} />
                    <TableFilter
                        data={STATUS_OPTIONS}
                        value={status}
                        setValue={setStatus}
                        filterName="Status"
                    />
                    <span style={{ marginRight: "20px" }}>
                        <SearchBtn2 search={searchItem} setSearch={setSearchItem} label={"Search By Business Name"} />
                    </span>

                </Grid>
                <CardContent>
                    <DataTable
                        columns={columns}
                        data={paginData?.data}
                        pagination
                        paginationServer
                        paginationTotalRows={paginData?.totalCount}
                        paginationPerPage={payload?.pageSize}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        onChangeRowsPerPage={(currentRowPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowPerPage, pageNumber: currentPage })
                        }}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}

                        sortServer
                        onSort={handleSort}

                    />

                </CardContent>
            </Card>
        </>
    )
}

export default MerchantPayout