import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import ConfirmModal from './confirmModal';
import { MerchantPayoutServices } from 'src/api/merchantPayoutServices';
import { toast } from 'react-toastify';
import errorHandler from 'src/utils/errorHandler';
import ShowRemarks from './showRemarks';





const MerchantPayoutExcelBtn = ({ callback, showCancelledButton, merchantData, data, id = null }) => {

    const [loading, setLoading] = useState(false)
    const [newStatus, setNewStatus] = useState("")
    const [showRemarkModal, setShowRemarksModal] = useState(false)
    const sheetName = "Merchant Payout"
    const [show, setShow] = useState(false)


    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(sheetName);

        // Merge cells for the main headers
        worksheet.mergeCells('A1:C1');
        worksheet.mergeCells('D1:F1');

        // Add main header titles
        worksheet.getCell('A1').value = 'Merchant Details';
        worksheet.getCell('D1').value = 'Bank Details';
        worksheet.getCell('G1').value = 'Payout Amount';

        // Style the merged header cells with specific colors
        worksheet.getCell('A1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D9E2F4' }, // Light Orange
        };

        worksheet.getCell('D1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E2EFD9' }, // Light Green
        };

        const payoutAmountHeader = worksheet.getCell('G1');
        payoutAmountHeader.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FBE4D5' }, // Light Yellow
        };
        payoutAmountHeader.alignment = { vertical: 'middle', horizontal: 'center' };

        // Define the subheader for payout amount
        worksheet.getCell('G2').value = 'Payout Amount';

        // Style the header cells
        const headerStyle = { bold: true, size: 12 };
        worksheet.getRow(1).font = headerStyle;

        // Style the subheader cell
        const subHeaderStyle = { bold: false, size: 12 }; // Set font weight to normal
        worksheet.getRow(2).font = subHeaderStyle;

        // Add the subheaders for other columns
        worksheet.getRow(2).values = [
            'Business Name',
            'Business Address',
            'Phone',
            'Account Type',
            'Account',
            'Routing',
            ""
        ];

        // Style the subheader row without background color
        const subHeaderRow = worksheet.getRow(2);
        subHeaderRow.eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } },
            };
        });

        // Define the columns
        worksheet.columns = [
            { key: 'businessName', width: 15 },
            { key: 'businessAddress', width: 25 },
            { key: 'phone', width: 15 },
            { key: 'accountType', width: 15 },
            { key: 'accountNumber', width: 20 },
            { key: 'routingNumber', width: 15 },
            { key: 'payoutAmount', width: 15 },
        ];

        // Add empty rows for styling demonstration
        data?.forEach((d, rowIndex) => {
            let newRow = [d?.businessName, d?.address, d?.phoneNumber, d?.bankAccountType, d?.bankAccountNumber, d?.routingNumber, d?.totalAmount]
            const row = worksheet.addRow(newRow);
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } },
                };
            });
        });


        // Set alignment for all cells in the worksheet
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });
        });

        // Generate buffer and save the file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${sheetName}.xlsx`);
    };


    const handleStatus = async (remarks) => {
        try {
            const payload = {
                "status": newStatus,
                "remarks": remarks
            }

            if (id) {
                payload.merchantId = merchantData?.merchantId
            }
            setLoading(true)
            let res = await MerchantPayoutServices.changeStatus(payload)
            toast.success(res.data?.message)
            setShowRemarksModal(false)
            callback()

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    const handleConfirm = () => {
        setShowRemarksModal(true)
        setShow(false)
    }

    return (
        <>
            {!showCancelledButton ?
                data?.length > 0 ? <>

                    <Button
                        size='small'
                        type="button"
                        variant="contained"
                        style={{ padding: "8px 12px" }}
                        onClick={() => {
                            setShow(true)
                            setNewStatus(1)
                        }}

                    >
                        Settle Payment
                    </Button>
                    <Button
                        size='small'
                        type="button"
                        variant="contained"
                        style={{ padding: "8px 12px" }}
                        onClick={() => {
                            exportToExcel()
                        }}

                    >
                        Download Excel
                    </Button>
                </> : null
                :
                merchantData?.status == "0" ?
                    <> 
                     <Button
                        size='small'
                        type="button"
                        variant="contained"
                        disabled={!(merchantData?.bankAccountNumber && merchantData?.routingNumber)}
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                            setShow(true)
                            setNewStatus(1)
                        }}
                    >
                        Settle Payment
                    </Button>
                        <Button
                            size='small'
                            type="button"
                            variant="contained"
                            onClick={() => {
                                setShow(true)
                                setNewStatus(3)
                            }}
                        >
                            Cancel Payment
                        </Button>
                    </>
                    : ""
            }

            {show && <ConfirmModal show={show} setShow={setShow} msg={"Are you sure want to change the status?"} handleSwitch={handleConfirm} loading={loading} />}
            {showRemarkModal ? <ShowRemarks show={showRemarkModal} setShow={setShowRemarksModal} handleStatus={handleStatus} loading={loading} /> : ""}
        </>
    );
};

export default MerchantPayoutExcelBtn;
